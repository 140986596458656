import React from "react";
import famPic from "../../assets/famPic.jpg";
import "./famPic.css";

const FamPic = () => (
  <img
    src={famPic}
    className="Lead-pic"
    alt="kabligh fam pic by Marija Tiurina"
  />
);

export default FamPic;
