import React, { Component } from "react";
import "./App.css";
import FamPic from "./components/FamPic/famPic";

class App extends Component {
  render() {
    return (
      <div className="App">
        <FamPic />
        <h1>ka-yee annabel bligh</h1>
      </div>
    );
  }
}

export default App;
